import { getStrapiMedia } from "../../../utils/media"
import Image from "next/legacy/image";
import PropTypes from "prop-types"
import { mediaPropTypes } from "../../../utils/types"

const NextImage = ({ media, ...props }) => {
  if (!media) return null;
  if (!media.data) return null;
  
  if (!media.data.attributes) return null;
  const { url, alternativeText, width, height } = media.data.attributes

  const loader = ({ src, width }) => {
    return getStrapiMedia(src)
  }

  // The image has a fixed width and height
  if (props.width && props.height) {
    return (
      <Image loader={loader} 
            src={url} 
            alt={alternativeText || ""} {...props} 
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"/>
    )
  }

  // The image is responsive
  return (
    <Image
      loader={loader}
      layout="responsive"
      width={width || "100%"}
      height={height || "100%"}
      objectFit="contain"
      src={url}
      alt={alternativeText || ""}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
  )
}

Image.propTypes = {
  media: mediaPropTypes.isRequired,
  className: PropTypes.string,
}

export default NextImage