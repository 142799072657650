import Spinner from "../../icons/spinner"
import clsx from "clsx"
import React from "react"

// type ButtonProps = {
//   isLoading?: boolean
//   variant?: "primary" | "secondary"
// } & React.ButtonHTMLAttributes<HTMLButtonElement>

// buttons used along the add-to-cart and purchase flow


// className="w-full uppercase flex items-center justify-center min-h-[50px] px-5 py-[10px] text-small-regular border transition-colors duration-200 disabled:opacity-50
    // text-secondary-900 bg-transparent border-secondary-920 hover:bg-secondary-100">
    
const Button = ({
  children,
  className,
  isLoading = false,
  variant = "primary",
  ...props
}) => {

  //console.log("btn has props: %s", props)
  return (
    <button {...props}
      className={clsx(
        "w-full uppercase flex items-center justify-center min-h-[50px] px-5 py-[10px] text-small-regular border transition-colors duration-200 disabled:opacity-50",
        {
          "text-white bg-secondary-900 border-secondary-900 hover:bg-white hover:text-secondary-900 disabled:hover:bg-secondary-900 disabled:hover:text-white":
            variant === "primary",
          "text-secondary-900 bg-transparent border-secondary-920 hover:bg-secondary-100":
            variant === "secondary",
        },
        className
      )}
    >
      {isLoading ? <Spinner /> : children} 
    </button>
  )
}

export default Button