import { useState } from "react"
import Navbar from "./elements/navbar"
import Footer from "./elements/footer"
import NotificationBanner from "./elements/notification-banner"
import clsx from "clsx"


export default function Layout ({ children, global, pageContext }) {
  const { navbar, footer, notificationBanner } = global.attributes
  const [bannerIsShown, setBannerIsShown] = useState(true)
  
  return (
    <>
    <div className="flex flex-col justify-between min-h-screen">
      {/* Aligned to the top */}
      <div className="flex-1">
        {notificationBanner && bannerIsShown && (
          <NotificationBanner
            data={notificationBanner}
            closeSelf={() => setBannerIsShown(false)}
          />
        )}
        <Navbar navbar={navbar} pageContext={pageContext} />
        {/* <main className={clsx("relative", staatliches.className, pacifico.className, lato.className)}> */}
        <main className={clsx("relative mt-12")}>
          {children}
        </main>
      </div>
      {/* Aligned to the bottom */}
      <Footer footer={footer} locale={pageContext?.locale} />
    </div>
    </>
  )
}