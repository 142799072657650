import React from 'react'
import { useEffect, useState } from 'react'

const ShoppingBag = ({ storeId = 13433173, closeSelf}) => {

    const [ecwidLoaded, setEcidLoaded] = useState(false)

    useEffect(() => {

        let ecwidLoaded = false;

        function load_ecwid() {
            if (!ecwidLoaded) { 
                if (typeof Ecwid != 'undefined') {
                    Ecwid.OnAPILoaded.add(function () {
                        setEcidLoaded(true)
                        Ecwid.init();
                    });
                }
            } else {
                Ecwid.refreshConfig();
            }
        }
        
        window.ec = window.ec || {};
        window.ec.config = window.ec.config || {};
        window.ec.config.storefrontUrls = window.ec.config.storefrontUrls || {};
        window.ec.config.storefrontUrls.cleanUrls = true;
        window.ec.config.baseUrl = '/store';
  
        window.ecwid_script_defer = true;
        window.ecwid_dynamic_widgets = true;

        if (!document.getElementById('ecwid-script')) {
            var script = document.createElement('script');
            // script.charset = 'utf-8';
            script.type = 'text/javascript';
            //script.src = 'https://app.ecwid.com/script.js?' + storeId + '&data_platform=nextjs';
            script.src = 'https://app.ecwid.com/script.js?' + storeId;
            script.id = 'ecwid-script'
            script.onload = load_ecwid
            document.body.appendChild(script);
        } else {
            load_ecwid()
        }
    })

    return (
        <div onClick={closeSelf} data-layout="MEDIUM_ICON_COUNTER" className="ec-cart-widget hover:text-crimson"></div>
    )
}

export default ShoppingBag