"use client"

import PropTypes from "prop-types"
import { MdClose, MdChevronRight } from "react-icons/md"
import { mediaPropTypes, linkPropTypes, buttonLinkPropTypes } from "../../../utils/types"
import { useLockBodyScroll } from "../../../utils/hooks"
import ButtonLink from "../button-link"
// import NextImage from "../image"
import CustomLink from "../custom-link"
import Image from "next/image"
import Script from "next/script"
import nameLogoUrl from "../../logos/logo-name-two.svg?url"
//import CartButton from "../cart-button"
import AccountButton from "../account-button"
import ShoppingBag from "../../ecwid/shopping-bag"
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const MobileNavMenu = ({ navbar, closeSelf }) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      closeSelf()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, closeSelf])

  return (
    <div className="fixed top-0 left-0 bg-white z-10 max-w-xs">
      <div className="container h-full flex flex-col">
        {/* Top section */}
        <div className="flex flex-row space-around py-2 items-stretch">
          {/* Close button */}
          <button onClick={closeSelf} className="py-1 px-1">
            <MdClose className="h-8 w-auto hover:text-crimson transition duration-150 ease-in-out" />
          </button>
          <ShoppingBag closeSelf={closeSelf} storeId="100774270"/>
        </div>
        {/* Main section */}
        <div className="flex flex-col justify-end w-9/12 mx-auto" >
          <ul className="flex flex-col list-none gap-6 items-baseline text-xl mb-10">
            <li key="store-menu" className="block w-full py-6 flex flex-row justify-between items-center">
              <CustomLink link={{ url: '/store', id: 'store-mobile-menuiten', text: 'Crispy Bars' }} legacyBehavior>
                <div className="flex flex-row py-2 px-4 justify-between items-center text-chocolate hover:text-crimson transition duration-150 ease-in-out"  >
                  <span>Shop Here!</span>
                  <MdChevronRight className="h-8 w-auto" />
                </div>
              </CustomLink>
            </li>
            {navbar.links.map((navLink) => (
            <li key={navLink.id} className="block py-2 px-4 items-center text-chocolate hover:text-crimson transition duration-150 ease-in-out">
              <CustomLink link={navLink} legacyBehavior>
                <div className="hover:text-crimson transition duration-150 ease-in-out py-6 flex flex-row justify-between items-center">
                  <span>{navLink.text}</span>
                  <MdChevronRight className="h-8 w-auto" />
                </div>
              </CustomLink>
            </li>
            ))}
            {/* <li className="block py-2 px-4 items-center text-chocolate hover:text-crimson transition duration-150 ease-in-out"><AccountButton/ ></li> */}
          </ul>
          {/* <Image width="170" height="150" src={navbar.logo} alt='Browned & Toasted 2023'/> */}
          {/* Bottom section */}
          <Image className="w-full" 
              height="640" 
              width="400" 
              src={nameLogoUrl} 
              alt='Browned & Toasted 2024'
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" />
          <ButtonLink
            button={navbar.button}
            appearance={"dark"}
          />
        </div>
      </div>
    </div>
  )
}

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  closeSelf: PropTypes.func,
}

export default MobileNavMenu