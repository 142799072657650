
import { useRouter } from "next/router"
import Link from "next/link"

const AccountButton = ({}) => {
    const router = useRouter()

    return (
        <>
        <button className="relative focus:outline-none focus:text-primary-900" aria-label="User account" onClick={() => {}} >
            <Link href="/store/account"  className="h-10 w-auto hover:text-crimson transition duration-150 ease-in-out" aria-label="Account"><p>Account</p>
           </Link>
        </button>
        </>
    )
}

export default AccountButton
