import { ErrorMessage } from "@hookform/error-message"
import Eye from "../../icons/eye"
import EyeOff from "../../icons/eye-off"
import clsx from "clsx"
import React, { useEffect, useImperativeHandle, useState } from "react"
import { get } from "react-hook-form"

// type InputProps = Omit<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   "placeholder"
// > & {
//   label: string
//   errors?: Record<string, unknown>
//   touched?: Record<string, unknown>
//   name: string
// }

const Input = React.forwardRef(
  ({ type, name, label, errors, touched, required, ...props }, ref) => {
    const inputRef = React.useRef(null)
    const [showPassword, setShowPassword] = useState(false)
    const [inputType, setInputType] = useState(type)

    useEffect(() => {
      if (type === "password" && showPassword) {
        setInputType("text")
      }

      if (type === "password" && !showPassword) {
        setInputType("password")
      }
    }, [type, showPassword])

    useImperativeHandle(ref, () => inputRef.current)

    const hasError = get(errors, name) && get(touched, name)

    return (
      <div>
        <div className="relative z-0 w-full text-base-regular">
          <input
            id={name}
            type={inputType}
            name={name}
            placeholder=" "
            // className={clsx(
            //   "pt-4 pb-1 block w-full px-4 mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200",
            //   {
            //     "border-crimson-500 focus:border-crimson-500": hasError,
            //   }
            // )}
            className="peer w-full border-0 placeholder:text-transparent bg-transparent"
            {...props}
            ref={inputRef}
          />
          <label
            htmlFor={name}
            onClick={() => inputRef.current?.focus()}
          //   className={clsx(
          //     "mx-3 px-1 transition-all absolute duration-300 top-3 origin-0 text-gray-500",
          //     {
          //       "!text-crimson-500": hasError,
          //     }
          //   )}
          // >
            className="focus:outline-none absolute left-0 ml-1 -translate-y-3 bg-transparent px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-small peer-placeholder-shown:text-secondary-500 "
            >
            {label}
            {required && <span className="text-crimson-500">*</span>}
          </label>
          {type === "password" && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-secondary-400 px-4 focus:outline-none transition-all duration-150 outline-none focus:text-secondary-700 absolute right-0 top-3"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          )}
        </div>
        {hasError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => {
              return (
                <div className="pt-1 pl-2 text-crimson -500 text-xsmall-regular">
                  <span>{message}</span>
                </div>
              )
            }}
          />
        )}
      </div>
    )
  }
)

Input.displayName = "Input"

export default Input