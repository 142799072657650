import Link from "next/link"
//import nameLogoUrl from "../../logos/name-only-two.svg?url"
import Image from "next/image"

const LogoButton = ({media}) => {
  const url = media ? media.data.attributes.url : ""
  const alt = media ? media.data.attributes.alternativeText : 'Browned & Toasted Home'
  const width = media ? media.data.attributes.width : 2048
  const height = media ? media.data.attributes.height : 1024

    return (
      <div className="">
        <Link href="/index" >
          {/* <Image height="56" className="p-0 m-0 w-full h-10" src={nameLogoUrl} alt='Browned & Toasted Home' /> */}
          <Image height="56" 
              className="p-2 m-0" 
              src={url} 
              alt='Go to Browned & Toasted Homepage' 
              width={256} 
              objectFit='cover' 
              layout='full'
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        </Link>
      </div>)
  }

export default LogoButton