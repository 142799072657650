import Link from 'next/link'
import PropTypes from 'prop-types'
import { linkPropTypes } from '../../../utils/types'
import {forwardRef} from 'react'

// const AnchoredForwardChild = forwardRef(({ children, href }, ref) => {
//   return (
//     <a href={href} ref={ref}>
//       {children}
//     </a>
//   )
// })
// AnchoredForwardChild.displayName = 'ForwardedAnchor'

const CustomLink = ({ link, children, }) => {
  if (!link) return null;
  
  const isInternalLink = link ?. url ?. startsWith("/") || link ?. url ?. startsWith("#") || false
  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link title={link.url} href={link.url} passHref >
        {children}
      </Link>
    )
  }

  // Plain <a> tags for external links
  if (link.newTab) {
    return (
      <a aria-label={link.text} href={link.url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  return (
    <a aria-label={link.text} href={link.url} target="_self" >
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
CustomLink.displayName = 'CustomLink'
export default CustomLink