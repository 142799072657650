var pluralize = require('pluralize');
const qs = require('qs');

const strapiApiToken = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN || ""

export function getNextApiRouteUrl(path) {
 // return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:3000"}/api${path}`
  return `${"http://localhost:3000"}/api${path}`
}

export function getStrapiURL(path) {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://127.0.0.1:1337"}/api${path}`
}

export function getStrapiMedia(url) {
  if (url == null) {
    return null;
  }
  if (url.startsWith('http') || url.startsWith('//')) {
    return url;
  }
  return `${process.env.NEXT_PUBLIC_API_URL || 'http://127.0.0.1:1337'}${url}`;
}

export function handleRedirection(preview, custom) {
  if (preview) {
    return {
      redirect: {
        destination: `/api/exit-preview`,
        permanent: false,
      },
    };
  } else if (custom) {
    return {
      redirect: {
        destination: `/${custom}`,
        permanent: false,
      },
    };
  } else {
    return {
      redirect: {
        destination: `/`,
        permanent: false,
      },
    };
  }
}

// export function getData(slug, locale, apiID, kind, preview) {
//   const previewParams = preview
//     ? '&publicationState=preview&published_at_null=true'
//     : '';

//   if (kind == 'collectionType') {
//     let prefix = `/${pluralize(apiID)}`;
//     if (apiID == 'page') {
//       prefix = ``;
//     } else if (apiID == 'article') {
//       prefix = `/blog`;
//     }
//     const slugToReturn = `${prefix}/${slug}?lang=${locale}`;
//     const apiUrl = `/${pluralize(
//       apiID
//     )}?filters[slug][$eq]=${slug}&locale=${locale}${previewParams}&populate[blocks][populate]=members.picture,header,buttons.link,faq,featuresCheck,cards,pricingCards.perks,articles,restaurants,author.picture,images,cards.image,image&populate=localizations&populate[seo][populate]=metaSocial.image`;

//     return {
//       data: getStrapiURL(apiUrl),
//       slug: slugToReturn,
//     };
//   } else {
//     const apiUrl = `/${apiID}?locale=${locale}${previewParams}&populate[blocks][populate]=*,buttons.link&populate=localizations&populate[header]=*&populate[seo]=metaSocial`;

//   //  `populate[sections][on][sections.hero] [on][sections.largevideo] [on][sections.featurecolumnsgroup] [on][sections.featurerowsgroup] [on][sections.bottomactions] [on][sections.frequentlyaskedquestions]`
//     if (apiID.includes('-page')) {
//       const slugToReturn =
//         apiID == 'blog-page'
//           ? `/${apiID.replace('-page', '')}?lang=${locale}`
//           : `/${apiID.replace('-page', 's')}?lang=${locale}`;
//       return {
//         data: getStrapiURL(apiUrl),
//         slug: slugToReturn,
//       };
//     } else {
//       return {
//         data: getStrapiURL(apiUrl),
//         slug: `/${apiID}?lang=${locale}`,
//       };
//     }
//   }
// }

// export async function subscribeEmail(email) {
//   const url = getMedusaURL('/mailchimp/subscribe')
//   const res = await fetch(url, { body: email })

//   if (!res.ok) {
//     throw new Error(`Failed to fetch order: ${id}`)
//   }

//   return res.status
// }

export async function getBarsData(barData, preview = false) {
  let publicationState = preview ? 'preview' : 'live'
  let baseUrl = getStrapiURL(
    `/products?locale=${barData.locale}&publicationState=${publicationState}&populate=product_type,images,thumbnail,ingredients,description`);
  //console.log('getBarsData baseUrl=%s', baseUrl)

  const barsRes = await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })

  if (!barsRes.ok) {
    throw new Error(`Failed to get bars data ${barData}`)
  }

  const bars = await barsRes.json()
  //console.log('bars=%s', bars.data)
  
  return bars.data
}

// export async function getBarData(barData) {

//   //https://brownedandtoasted.xyz/api/api/products?filters[handle][$eq]=original-marshmallow-crispy
//   let baseUrl = getStrapiURL(`/products?filters[handle][$eq]=${barData.handle}&locale=${barData.locale}&populate=product_type,images,ingredients,thumbnail,ingredients.name,ingredients.description`);
//   console.log('getBarData baseUrl=%s', baseUrl)

//   const barsRes = await fetch(baseUrl)
//   const bars = await barsRes.json()
//   console.log('bars=%s', bars.data)

//   return bars.data[0]
// }

/**
 * 
 * @param {*} param0 
 */
export async function getBarData({ handle, locale, preview }) {
  //console.log('getBarData for %s %s preview: %s',handle,locale,preview)
  locale = locale || 'en'

  const query = qs.stringify({
    filters: {
      handle: {
        $eq: handle
      }
    },
    fields: '*',
    locale: [locale],
    populate: '*',
    // populate: {
    //   ingredients: '*',
    // }
  }, {
    encodeValuesOnly: true, // prettify URL
  })

  let baseUrl = getStrapiURL(`/products?${query}`)
  console.log('getBarData baseUrl=%s', baseUrl)

  const bars = await (await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })).json()
  return bars.data[0]
}

export async function getEvents({ locale, preview }) {
 // console.log('getEvents for %s preview: %s',locale,preview)
  locale = locale || 'en'

  const query = qs.stringify({
    filters: {
      startsAt: {
        $gte: Date.now.toISOString
      }
    },
    locale: [locale],
    populate: '*',
    populate: {}
  }, {
    encodeValuesOnly: true, // prettify URL
  })

  
  let baseUrl = getStrapiURL(`/events?${query}`)
//  console.log('getEvents baseUrl=%s', baseUrl)

  const events = await (await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })).json()
  return events.data
}



/**
 * 
 * @param {string} locale ISO 2-letter language code
 * @returns 
 */
export async function getGlobalData(locale) {

  locale = locale || 'en'
  
  let globalPopulate = 'favicon,'
  let metadataPopulate = 'metadata,metadata.metaSocial,metadata.metaImage,metadata.metaTitle,metadata.metaImage.media'
  let navbarPopulate = 'navbar,navbar.logo,navbar.links,navbar.ctaButton'
  let footerPopulate = 'footer,footer.columns,footer.links,footer.logo,footer.columns.links,footer.smalltext,footer,footer.logo'
  let bannerPopulate = 'notificationBanner'
  
  let baseUrl = getStrapiURL(
    `/global?locale=${locale}&populate=${globalPopulate},${metadataPopulate},${navbarPopulate},${footerPopulate},${bannerPopulate}`)
  //console.log('getGlobalData baseUrl=%s', baseUrl)

  const globalRes = await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })
  const global = await globalRes.json()
  //console.log('global=%s', global.data)

  return global.data
}

function sectionsMap() {
  return {
    'sections.hero': {
      fields: ['title','label','description','smallTextWithLink'],
      populate: ['buttons','picture','picture.media','buttons.icon','color']
    },
    'sections.large-video':{
      fields: ['title','description'],
      populate: ['video','poster','video.media','color']
    },
    'sections.rich-text':{
      fields: ['content'],
      populate: ['color']
    },
    'sections.feature-columns-group':{
      fields: ['*',],
      populate: ['features','color','features.icon']
    },
    // 'sections.feature-rows-group': {
    //   fields: ['*',],
    //   populate: []
    // }, 
    'sections.bottom-actions': {
      fields: ['title'],
      populate: ['buttons','buttons.icon.media','color']
    },
    'sections.frequently-asked-questions': {
      fields: ['title','description'],
      populate: ['contactInformation','color','questionsAnswers'],
    },
    'sections.similar-products': {
      fields: ['*',],
      populate: ['color'],
    },
    'sections.testimonials': {
      fields: ['title',],
      populate: ['color'],
    },
    'sections.contact-us': {
      fields:['title','description'],
      populate:['color','contactInformation']
    },
    'sections.upcoming-events': {
      fields: ['*'],
      populate: ['color'],
    },
    'sections.retailers': {
      fields: ['title'],
      populate: ['retailers', 'color']
    },
    'sections.soft-offer': {
      fields: ['title', 'offer'],
      populate: ['offerForm', 'color', 'ctaButton']
    }
  }
}
/**
 * 
 * @param {*} param0 
 */
export async function getBarPageData({ handle, locale, preview }) {
 // console.log('getBarPageData for %s %s preview: %s',handle,locale,preview)
  locale = locale || 'en'

  const query = qs.stringify({
    filters: {
      handle: {
        $eq: handle
      }
    },
    locale: [locale],
    populate: '*',
    populate: {
      metadata: '*',
      localizations: '*',
      sections: '*',
      sections: { on:  sectionsMap() }    
    }
  }, {
    encodeValuesOnly: true, // prettify URL
  })

  let baseUrl = getStrapiURL(`/bar-product-page?${query}`)
  //console.log('getBarPageData for %s ', baseUrl)

  const pageRes = await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })
  const pageData = await pageRes.json()
  
  if (pageData.error ) {
    //console.log('returning bar-pagedata.error=%s', pageData.error);
    return { error: pageData.error };
  }
  //console.log('bar-pagedata.data=%s', pageData.data)
  
  // Make sure we found something, otherwise return null
  if (!pageData || !pageData.data ) {
    return null
  }
  
  //console.log('bar-page=%s', pageData.data)

  // Return the first item since there should only be one result per slug
  return pageData.data
}

/**
 *
 * @param {Object} options
 * @param {string} options.slug The page's slug
 * @param {string} options.locale The current locale specified in router.locale
 * @param {boolean} options.preview router isPreview value
 */
export async function getPageData({ slug, locale, preview }) {
  locale = locale || 'en'


  const query = qs.stringify({
    filters: {
      slug: {
        $eq: slug
      }
    },
    locale: ['en'],
    populate: '*',
    populate: {
      metadata: '*',
      localizations: '*',
      sections: '*',
      sections: {
          on: sectionsMap()     
      }
    }
  }, {
    encodeValuesOnly: true, // prettify URL
  })
  
  let baseUrl = getStrapiURL(`/pages?${query}`)
 // console.log('getPageData for %s -> %s ', slug, baseUrl)

  const pageRes = await fetch(baseUrl, {headers: {"Authorization" : `bearer ${strapiApiToken}`} })
  const pageData = await pageRes.json()
  
  if (pageData.error ) {
    //console.log('returning pagedata.error=%s', pageData.error);
    return { error: pageData.error };
  }
  //console.log('pagedata.data=%s', pageData.data)
  
  // Make sure we found something, otherwise return null
  if (!pageData || !pageData.data || pageData.data.size == 0) {
    return null
  }
  
  // console.log('page=%s', pageData.data[0].attributes)

  // Return the first item since there should only be one result per slug
  if (pageData.data[0])
    return pageData.data[0].attributes
  else
    return {}
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {RequestInit} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path, urlParamsObject = {}, options = {}) {

  console.log('entering fetchAPI:', path)
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization" : `bearer ${strapiApiToken}`
    },
    ...options,
  }

  // Build request URL
  const qs = new URLSearchParams(urlParamsObject)
  const queryString = qs.toString()
  const requestUrl = `${getStrapiURL(
    `${path}${queryString ? `?${queryString}` : ""}`
  )}`

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions)

  // Handle response
  if (!response.ok) {
    console.error(response)
    throw new Error(`An error occured please try again`)
  }
  const data = await response.json()
  return data
}

export async function fetchRoute(path, urlParamsObject = {}, options = {}) {

  console.log('entering fetchRoute:', path)
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
      // "Authorization" : `bearer ${strapiApiToken}`
    },
    ...options,
  }

  // Build request URL
  const qs = new URLSearchParams(urlParamsObject)
  const queryString = qs.toString()
  const requestUrl = `${getNextApiRouteUrl(
    `${path}${queryString ? `?${queryString}` : ""}`
  )}`

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions)

  // Handle response
  if (!response.ok) {
    console.error(response)
    throw new Error(`An error occured please try again`)
  }
  const data = await response.json()
  return data
}