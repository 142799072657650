import NextImage from '../image'
import CustomLink from '../custom-link'
import Button from "../button"
import PropTypes from 'prop-types'
import classNames from "classnames"
import { buttonLinkPropTypes } from '../../../utils/types'

export function ButtonContent({ button, appearance, compact }) {
    return(
    <div className={classNames(
        // Common classes
        "flex hover:text-crimson border-crimson justify-center lg:w-auto text-center font-semibold text-base md:text-sm border-2 rounded-md m-1",
        // Full-size button
        {
            "px-4 py-2": compact === false,
        },
        // Compact button
        {
            "px-2 py-2": compact === true,
        },
        // Specific to when the button is fully dark
        {
            "uppercase bg-primary-600 border-primary-600 text-white": appearance === "dark",
        },
        // Specific to when the button is dark outlines
        {
            "text-primary-600 border-primary-600": appearance === "dark-outline",
        },
        // Specific to when the button is fully white
        {
            "bg-white text-primary-600 border-white": appearance === "white",
        },
        // Specific to when the button is white outlines
        {
            "text-white border-white": appearance === "white-outline",
        }
    )}>

        {button.type === 'image' &&
            button.icon &&
            (
                <div className="">
                    <NextImage width="160" height="124" media={button.icon} />
                </div>
            )}
        {button.text}
    </div>
    )
}

export default function ButtonLink({ button, appearance, compact = false }) {
    return (
    <CustomLink link={button}>
        <button type="button">
        <ButtonContent
            button={button} appearance={appearance} compact={compact} />
        </button>
    </CustomLink>)
}

ButtonLink.propTypes = {
    button: buttonLinkPropTypes,
    appearance: PropTypes.oneOf(["dark", "white-outline", "white", "dark-outline",]),
    compact: PropTypes.bool,
}